/*
 * Master Page Layout
 */

.page-wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: mix(@gray-lighter, #fafafa);
}


/* Set a maximum wrapper width */
@wrapper-width: 1440px;

@media (min-width: @wrapper-width) {
    .page-wrap {
        .box-shadow(0 0 10px rgba(0, 0, 0, 0.3));
        margin-right: auto;
        margin-left: auto;
        width: @wrapper-width;
    }
}

.page-content {
    flex-grow: 1;
    padding-bottom: @line-height-computed * 2;
}
