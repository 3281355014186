/*
 * Login and Password Styling
 */

.auth {
    .card-small {
    	width: 400px;
        max-width: 400px;
        padding: 30px;
        margin-left: @grid-gutter-width;
        margin-right: @grid-gutter-width;
    }
    .page-wrap {
        width: 100%;
    }
    .remember {
        font-size: 14px;
    }
    .form-actions {
    	margin: 30px 0;
    }
}
