/**
 * LESS Mixins
 */

// Typography
// -------------------------------------------------- //
.word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.truncate(@width: 100%) {
    width: @width;
    .ellipsis();
}

// Forms
// -------------------------------------------------- //
.placeholder(@color) {
    &::-webkit-input-placeholder {
        color: @color;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        color: @color;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        color: @color;
    }
    &:-ms-input-placeholder {
        color: @color;
    }
}

// Overlays & Fills
// -------------------------------------------------- //
.fill() {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.overlay(@background: rgba(0, 0, 0, 0.5)) {
    .fill();
    background: @background;
}

.bg-image(@position: center center) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: @position;
}

// Icon Buttons
// -------------------------------------------------- //
.icon-base(@icon-size: 48px;
@font-size: ceil(@icon-size/2);
@border-radius: 100%;
@transition: all 0.3s ease) {
    position: relative;
    display: inline-block;
    padding: 0 !important;
    width: @icon-size;
    height: @icon-size;
    border-radius: @border-radius;
    text-align: center;
    font-size: @font-size;
    line-height: @icon-size !important;
    transition: @transition;
}


/* Icon Button Colors */

.icon-variant(@bg-color: transparent;
@text-color: #fff;
@bg-color-hover: darken(@bg-color, 10%);
@text-color-hover: @text-color;
@border-color-hover: inherit) {
    background: @bg-color !important;
    color: @text-color !important;
    &:hover {
        background: @bg-color-hover !important;
        color: @text-color-hover !important;
        border-color: @border-color-hover !important;
    }
}


/* Need to Redeclare */

.fa() {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.fa-fw() {
    width: 1.28571429em;
    text-align: center;
}

// Arrows (CSS Triangles)
// -------------------------------------------------- //
.arrow-base(@size: 20px;
@z-index: 2) {
    position: absolute;
    z-index: @z-index;
    width: 0;
    height: 0;
    border: @size solid transparent;
    content: '';
}

.arrow-up(@color: #fff;
@size: 20px;
@left: 50%) {
    .arrow-base(@size);
    left: @left;
    margin-left: -@size;
    border-bottom-color: @color;
}

.arrow-down(@color: #fff;
@size: 20px;
@left: 50%) {
    .arrow-base(@size);
    left: @left;
    margin-left: -@size;
    border-top-color: @color;
}

.arrow-left(@color: #fff;
@size: 20px;
) {
    .arrow-base(@size);
    border-right-color: @color;
}

.arrow-right(@color: #fff;
@size: 20px;
) {
    .arrow-base(@size);
    border-left-color: @color;
}

// Grids
// -------------------------------------------------- //
.grid-alt(@gutter-width: 15px) {
    .row {
        margin-left: -@gutter-width / 2;
        margin-right: -@gutter-width / 2;
    }
    .col-xs-1,
    .col-sm-1,
    .col-md-1,
    .col-lg-1,
    .col-xs-2,
    .col-sm-2,
    .col-md-2,
    .col-lg-2,
    .col-xs-3,
    .col-sm-3,
    .col-md-3,
    .col-lg-3,
    .col-xs-4,
    .col-sm-4,
    .col-md-4,
    .col-lg-4,
    .col-xs-5,
    .col-sm-5,
    .col-md-5,
    .col-lg-5,
    .col-xs-6,
    .col-sm-6,
    .col-md-6,
    .col-lg-6,
    .col-xs-7,
    .col-sm-7,
    .col-md-7,
    .col-lg-7,
    .col-xs-8,
    .col-sm-8,
    .col-md-8,
    .col-lg-8,
    .col-xs-9,
    .col-sm-9,
    .col-md-9,
    .col-lg-9,
    .col-xs-10,
    .col-sm-10,
    .col-md-10,
    .col-lg-10,
    .col-xs-11,
    .col-sm-11,
    .col-md-11,
    .col-lg-11,
    .col-xs-12,
    .col-sm-12,
    .col-md-12,
    .col-lg-12 {
        padding-left: @gutter-width / 2;
        padding-right: @gutter-width / 2;
    }
}
