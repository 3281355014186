/*
 * Button Styles
 */

.btn {
    .btn-alt(12px);
    transition: all 0.2s ease;
    text-transform: uppercase;
}

// Button Sizes
// -------------------------------------------------- //
.btn-xs {
    .btn-xs-alt(11px);
}

.btn-sm {
    .btn-sm-alt(11px);
}

.btn-lg {
    .btn-lg-alt(13px);
}

.btn-xl {
    padding: @padding-xl-vertical @padding-xl-horizontal;
    .btn-xl-alt(16px);
}

// Button Variants
// -------------------------------------------------- //
