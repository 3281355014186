/*
 * Thubnail Grid
 */

.thumb-grid {
    @thumb-margin: @grid-gutter-width;
    @thumb-gutter-width: @grid-gutter-width;
    @thumb-padding: 15px;
    // Container Setup
    display: flex;
    flex-flow: row wrap;
    margin-left: -@thumb-gutter-width;
    // Individual Thumbnails
    .thumb {
        background: #fff;
        border: 1px solid @panel-default-border;
        display: flex;
        flex-flow: column nowrap;
        flex: 0 1 auto;
        padding: @thumb-padding;
        margin-bottom: @thumb-margin;
        margin-left: @thumb-gutter-width;
        width:~"calc(100% - @{thumb-gutter-width})";
        .image {
            flex-grow: 1;
            text-align: center;
        }
    }
    .title {
        .truncate();
        font-size: 10px;
        color: @gray-light;
        margin-top: @thumb-padding;
    }
    img {
        width: 100%;
        max-width: 100%;
    }
    .counter {
        margin-top: @thumb-padding;
        font-size: 12px;
        text-align: center;
        color: @gray-light;
    }
    .index {
        color: @gray;
    }
    @media (min-width: 480px) {
        .thumb {
            width:~"calc(50% - @{thumb-gutter-width})";
        }
    }
    @media (min-width: @grid-float-breakpoint) {
        .thumb {
            width:~"calc(33.3333% - @{thumb-gutter-width})";
        }
    }
}
