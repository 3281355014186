/*
 * Global Header Styling
 */

.globalheader {
	background: #fff;
	padding: 30px 15px;
	margin-bottom: 60px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
