/**
 * Table Styles
 */

.table-plain > tbody > tr {
    & > td,
    & > th {
        border: none !important;
    }
}

.table-ultra-condensed tbody > tr > td {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}

table.sortable thead th a {
    position: relative;
    display: block;
    &.sorting::after,
    &.sorting-asc::after,
    &.sorting-desc::after {
        .fa();
        position: absolute;
        top: 50%;
        margin-left: 0.5em;
        margin-top: -0.5em;
    }
    &.sorting::after {
        content: "\f0dc";
        color: @gray-light;
    }
    &.sorting-asc::after {
        content: "\f0de";
    }
    &.sorting-desc::after {
        content: "\f0dd";
    }
}

tr.text-left {
    th,
    td {
        text-align: left;
    }
}

tr.text-center {
    th,
    td {
        text-align: center;
    }
}

tr.text-right {
    th,
    td {
        text-align: right;
    }
}

.table {
    & > thead,
    & > tfoot,
    & > tbody {
        tr {
            &.v-middle {
                th,
                td {
                    vertical-align: middle;
                }
            }
            th,
            td {
                &.v-middle {
                    vertical-align: middle;
                }
            }
            &.v-bottom {
                th,
                td {
                    vertical-align: bottom;
                }
            }
            th,
            td {
                &.v-bottom {
                    vertical-align: bottom;
                }
            }
            &.v-top {
                th,
                td {
                    vertical-align: top;
                }
            }
            th,
            td {
                &.v-top {
                    vertical-align: top;
                }
            }
        }
    }
}
