/*
 * A mobile-first grid based on flexbox.
 */

// Default to full-width (mobile-first)
[class^="flex-xs-"],
[class^="flex-sm-"],
[class^="flex-md-"],
[class^="flex-lg-"] {
    flex: 1 0 auto;
    width: 100%;
}

// Extra Small Screens and Up
// -------------------------------------------------- //
@media only screen and (min-width: @screen-xs-min) {
    .flex-xs-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-xs-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-xs-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-xs-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-xs-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-xs-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-xs-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-xs-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-xs-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-xs-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-xs-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-xs-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

// SmallScreens and Up
// -------------------------------------------------- //
@media only screen and (min-width: @screen-sm-min) {
    .flex-sm-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-sm-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-sm-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-sm-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-sm-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-sm-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-sm-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-sm-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-sm-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-sm-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-sm-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-sm-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

// Medium Screens and Up
// -------------------------------------------------- //
@media only screen and (min-width: @screen-md-min) {
    .flex-md-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-md-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-md-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-md-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-md-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-md-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-md-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-md-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-md-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-md-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-md-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-md-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

// Large Screens and Up
// -------------------------------------------------- //
@media only screen and (min-width: @screen-lg-min) {
    .flex-lg-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-lg-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-lg-3 {
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-lg-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-lg-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-lg-6 {
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-lg-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-lg-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-lg-9 {
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-lg-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-lg-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-lg-12 {
        flex-basis: 100%;
        max-width: 100%;
    }
}
