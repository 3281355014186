/*
 * Global Styles
 */

@line-height-xl: 1.333333;
@font-size-xl: 20px;
@padding-xl-vertical: 16px;
@padding-xl-horizontal: 50px;
@input-height-xl: (ceil(@font-size-xl * @line-height-xl) + (@padding-xl-vertical * 2) + 2);

// Cards
// -------------------------------------------------- //
.card {
    header {
        .margin-bottom-double;
        &.compact {
            .margin-bottom;
        }
        h3 {
            .no-margin();
            line-height: 34px;
            font-weight: 300;
        }
    }
}

.card-small {
    width: 400px;
    margin-left: @grid-gutter-width;
    margin-right: @grid-gutter-width;
}

.card-narrow {
    width: 550px;
}

.card-med {
    width: 700px;
}

.card-wide {
    width: 850px;
}

// Forms
// -------------------------------------------------- //
label {
	font-weight: 300;
}
.input-xl {
  .input-size(@input-height-xl; @padding-xl-vertical; @padding-xl-horizontal; @font-size-large; @line-height-xl; @input-border-radius-large);
}
