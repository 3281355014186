/**
 * Scaffolding: Links and Images
 */

// Links
// -------------------------------------------------- //
a.dismiss {
    position: absolute;
    top: 2px;
    right: 2px;
}

a img {
    border: none;
}

.icon-link .fa {
    margin-right: 0.5em;
}

.icon-link.icon-link-after .fa {
    margin-right: 0;
    margin-left: 0.5em;
}

a#top-link {
    position: fixed;
    right: 15px;
    bottom: 10%;
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 1px solid @gray-darker;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: @gray-lighter;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    line-height: 40px;
    cursor: pointer;
}

a#top-link:hover {
    border-color: @gray-dark;
    background: @gray-dark;
    color: #fff;
    text-decoration: none;
}

// Images
// -------------------------------------------------- //
.img-constrained {
    max-width: 100%;
    height: auto;
}
